:root {
  --shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;
$enable-reduced-motion: true !default;
// Spinners

// scss-docs-start spinner-variables
$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-vertical-align: -0.125em !default;
$spinner-border-width: 0.25em !default;
$spinner-animation-speed: 0.75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;
// scss-docs-end spinner-variables

//
body {
  --color: #222;
  --background-color: white;
}
.dialog {
  --background-color: white;
}
.text-loading {
  --color: #005fc4;
}
.dialog-header {
  --border-bottom-color: 1px solid #b4b4c6;
}
.dialog-footer {
  --button-color: #003ea9;
  --background-color: white;
}
.dialog-body {
  --button-background-color: #003ea9;
  --button-color: white;
  --input-code-label-color: #0051dc;
  --error-color: #d50000;
}
